<template>
  <div class="">
    <section class="height-100 masthead position-relative">
      <div class="masthead-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 col-md-6 text-center">
            <div class="card py-3 shadow-lg">
              <div class="card-body">
                <div class="mb-5">
                  <h2 class="text-center">Change password</h2>
                  
                </div>
                <form>
                   <div class="form-group">
                      <input
                        id="password"
                        type="Password"
                        class="form-control form-control-lg"
                        placeholder="New Password"
                        v-model="new_password"
                      />
                  </div>
                  <div class="form-group">
                      <input
                        id="confirmpassword"
                        type="password"
                        class="form-control form-control-lg"
                        placeholder="Confirm Password"
                        v-model="confirm_new_password"
                      />
                  </div>
                  <div class="form-group">
                    <button
                      ref="submitButton"
                      class="btn btn-block btn-lg btn-primary"
                      type="button"
                      @click="UpdatePassword"
                    >
                      <b-spinner v-if="isLoading" />
                      <span>
                      Update Password
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      old_password: '',
      warning: false,
      warning1: false,
      warning2: false,
      new_password: '',
      confirm_new_password: '',
      usersDetails: '',
      token: '',
      isLoading: false
    }
  },
  computed: {},
  created () {
    this.checkTokenExpiry()
  },
  methods: {
    async checkTokenExpiry () {
      this.$vs.loading()
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

      const urlencoded = new URLSearchParams()
      urlencoded.append('token', this.$route.params.token)

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      }

      const rawResponse = await fetch(
        `${process.env.VUE_APP_BASE_URL  }/api/checkTokenExpiry`,
        // 'http://localhost:7000/api/checkTokenExpiry',
        requestOptions
      )

      const response = await rawResponse.json()

      if (response.data[0].status === 0) {
        this.$vs.notify({
          title: 'Error !!',
          text: response.data[0].message,
          color: 'primary',
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
        setTimeout(() => this.$router.push('/login'), 1000)
      }
      this.$vs.loading.close()
    },
    async UpdatePassword () {
      this.isLoading = true
      if (this.new_password === '') {
        this.warning = false
        this.warning1 = true
        this.warning2 = true
        this.isLoading = false
        this.$vs.notify({
          title: 'Wrong Password !!',
          text: 'Please enter the new password',
          color: 'primary',
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
      } else if (this.new_password !== this.confirm_new_password) {
        this.warning = false
        this.warning1 = true
        this.warning2 = true
        this.isLoading = false
        this.$vs.notify({
          title: 'Wrong Password !!',
          text: 'Password Is Not Matching',
          color: 'primary',
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
      } else {
        this.$vs.loading()
        const myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

        const urlencoded = new URLSearchParams()
        urlencoded.append('token', this.$route.params.token)
        urlencoded.append('password', this.new_password)

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        }

        const rawResponse = await fetch(
          `${process.env.VUE_APP_BASE_URL  }/api/changeUserPassword`,
          requestOptions
        )

        const response = await rawResponse.json()
        if (response.data[0].status === 1) {
          this.$vs.notify({
            title: 'Password Updated!!',
            text: response.data[0].message,
            color: 'success',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
          this.old_password = ''
          this.new_password = ''
          this.confirm_new_password = ''
          this.warning = false
          this.warning1 = false
          this.warning1 = false
          this.isLoading = false
          setTimeout(() => this.$router.push('/login'), 1000)
        } else if (response.data[0].status === 0) {
          this.warning = true
          this.warning1 = false
          this.warning1 = false

          this.$vs.notify({
            title: 'Wrong Password !!',
            text: response.data[0].message,
            color: 'primary',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
          this.isLoading = false
        }
        this.$vs.loading.close()
      }
    }
  }
}
</script>

<style scoped>
body {
  font-family: "Open Sans", sans-serif;
  color: #626262;
  font-size: 16px !important;
}
p {
  font-size: 16px !important;
  margin-bottom: 1rem !important;
}
span {
  font-size: 16px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #515365;
  /* color:#282828; */
  font-weight: 600;
}
h4,
h6 {
  margin-bottom: 0.5rem;
}
.masthead-text {
  color: #fff;
  font-size: 20px !important;
}
.navbar-dark .navbar-nav .nav-link {
  font-size: 16px !important;
}
</style>
